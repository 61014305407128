import React, { createContext, FC } from 'react';

export const HeadingScopeContext = createContext(0);

const HeadingScope: FC = ({ children }) => (
  <HeadingScopeContext.Consumer>
    {level => (
      <HeadingScopeContext.Provider value={level + 1}>
        {children}
      </HeadingScopeContext.Provider>
    )}
  </HeadingScopeContext.Consumer>
);

export default HeadingScope;
