import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

const StyledTag = styled.div<TagProps>`
  align-items: center;
  background-color: ${colors.blue300};
  border-radius: 10px;
  box-sizing: border-box;
  color: ${colors.white};
  display: inline-flex;
  font-size: 12px;
  justify-content: center;
  min-width: 60px;
  padding: 2px 10px;
  width: auto;

  ${({ big }) =>
    big &&
    css`
      font-weight: 700;
      padding: 10px;
      text-transform: uppercase;
      ${mediaQuery('md')} {
        padding: 10px 20px;
      }
    `}
`;

interface TagProps {
  children: string | React.ReactNode;
  big?: boolean;
}

const Tag: FC<TagProps> = ({ big, children }) => (
  <StyledTag big={big}>{children}</StyledTag>
);

export default Tag;
