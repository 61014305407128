import { graphql } from 'gatsby';
import React, { FC } from 'react';

import SEO from 'src/components/Seo/Seo';
import Banner from 'src/components/Banner';
import { HeadingScope } from 'src/components/Heading';
import Main from 'src/components/Main';

interface EUProjectsProps {
  data: any;
}

const EUProjects: FC<EUProjectsProps> = ({ data }) => {
  const {
    wpPage: { seo, content },
    banner,
  } = data;

  return (
    <HeadingScope>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={seo.opengraphImage}
      />

      <Banner
        image={banner.childImageSharp.gatsbyImageData}
        title="Įgyvendinami projektai"
      />
      <Main>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Main>
    </HeadingScope>
  );
};

export const query = graphql`
  query EUProjects {
    wpPage(slug: { eq: "igyvendinami-projektai" }) {
      ...YoastSeoPage
      content
    }
    banner: file(relativePath: { eq: "pirkimo-salygos.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

export default EUProjects;
