import styled, { css } from 'styled-components';
import React, { FC } from 'react';

import { HeadingVariant } from './constants';
import { HeadingScopeContext } from './HeadingScope';
import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadingProps {
  id?: string;
  variant: HeadingVariant;
}

const baseHeadingStyles = css`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.2;
  margin: 0;
`;

const Text = styled.h1<HeadingProps>`
  ${({ variant }) =>
    variant === HeadingVariant.ALFA &&
    css`
      ${baseHeadingStyles}
      font-size: 42px;
      font-weight: 700;
      color: ${colors.white};
      text-align: center;
      text-transform: uppercase;
    `}

  ${({ variant }) =>
    variant === HeadingVariant.BRAVO &&
    css`
      ${baseHeadingStyles}
      font-size: 22px;
      font-weight: 700;
      text-transform: uppercase;
    `}

    ${({ variant }) =>
    variant === HeadingVariant.CHARLIE &&
    css`
      ${baseHeadingStyles}
      font-size: 18px;
    `}

    ${({ variant }) =>
    variant === HeadingVariant.DELTA &&
    css`
      ${baseHeadingStyles}
      font-size: 16px;
    `}

    ${({ variant }) =>
    variant === HeadingVariant.ECHO &&
    css`
      ${baseHeadingStyles}
      font-size: 14px;
    `}

    ${({ variant }) =>
    variant === HeadingVariant.FOXTROT &&
    css`
      ${baseHeadingStyles}
      font-size: 26px;
      font-weight: 700;
    `}

    ${mediaQuery('md')} {
    ${({ variant }) =>
      variant === HeadingVariant.ALFA &&
      css`
        font-size: 70px;
      `}

    ${({ variant }) =>
      variant === HeadingVariant.BRAVO &&
      css`
        font-size: 30px;
      `}

    ${({ variant }) =>
      variant === HeadingVariant.CHARLIE &&
      css`
        font-size: 24px;
      `}

      ${({ variant }) =>
      variant === HeadingVariant.DELTA &&
      css`
        font-size: 20px;
      `}

      ${({ variant }) =>
      variant === HeadingVariant.ECHO &&
      css`
        font-size: 16px;
      `}

      ${({ variant }) =>
      variant === HeadingVariant.FOXTROT &&
      css`
        ${baseHeadingStyles}
        font-size: 50px;
        font-weight: 700;
      `}
  } ;
`;

const Heading: FC<HeadingProps> = ({ children, variant, ...other }) => (
  <HeadingScopeContext.Consumer>
    {level => {
      const tag = `h${Math.max(Math.min(level, 6), 1)}`;

      return (
        <Text {...other} as={tag as HeadingTag} variant={variant}>
          {children}
        </Text>
      );
    }}
  </HeadingScopeContext.Consumer>
);

export default Heading;
