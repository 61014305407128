import React, { FC } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Heading, { HeadingVariant } from 'src/components/Heading';
import Button, { ButtonAppearance } from 'src/components/Button';
import StatsIcon from 'src/components/StatsIcon';
import Tag from 'src/components/Tag';
import { CalendarIcon, GlobeIcon, EuroIcon } from 'src/assets/icons';
import { mediaQuery } from 'src/theme/breakpoints';
import { colors } from 'src/theme/colors';

const BackgroundWrapper = styled.div<Pick<BannerProps, 'fullHeight'>>`
  height: auto;
  padding: 120px 0 40px;
  position: relative;

  ${mediaQuery('md')} {
    height: ${({ fullHeight }) => (fullHeight ? '100vh' : '400px')};
    padding: 80px 0 20px;
  } ;
`;

const Background = styled(GatsbyImage)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  &:after {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 1080px;
  padding: 0 20px;
  z-index: 1;
`;

const Subtitle = styled.p`
  color: ${colors.white};
  font-size: 14px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;

  ${mediaQuery('md')} {
    font-size: 16px;
  }
`;

const StatsWrapper = styled.div`
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  margin: 10px auto;
  width: 100%;

  ${mediaQuery('sm', 'max')} {
    & svg {
      max-width: 20px;
    }
  }

  ${mediaQuery('sm')} {
    margin: 40px auto;
    max-width: 800px;
  }
`;

const Categories = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin-top: -10px;
  padding: 0;
  width: 100%;
`;

const Category = styled.li`
  margin-top: 10px;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const ButtonWrapper = styled.div`
  margin: 40px 0 0;
  ${mediaQuery('md', 'max')} {
    width: 100%;
  }
`;

interface BannerProps {
  image: any;
  title: string;
  subtitle?: string;
  product?: {
    days: number;
    countries: string;
    regularPrice: string;
    salePrice?: string;
    categories: any; //TODO
  };
  button?: {
    title: string;
    url: string;
    isExternal?: boolean;
  };
  fullHeight?: boolean;
}

const Banner: FC<BannerProps> = ({
  image,
  title,
  subtitle,
  product,
  button,
  fullHeight,
}) => {
  const price = product?.salePrice ? product.salePrice : product?.regularPrice;

  return (
    <BackgroundWrapper fullHeight={fullHeight}>
      <Background image={image} alt={title} />
      <Content>
        <Heading variant={HeadingVariant.ALFA}>{title}</Heading>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {button && (
          <ButtonWrapper>
            <Button
              isExternal={button.isExternal}
              url={button.url}
              appearance={ButtonAppearance.BRAVO}
            >
              {button.title}
            </Button>
          </ButtonWrapper>
        )}
        {product && (
          <>
            <StatsWrapper>
              <StatsIcon
                icon={<CalendarIcon />}
                statTitle={`${product.days} d.`}
              />
              <StatsIcon icon={<GlobeIcon />} statTitle={product.countries} />
              {price && <StatsIcon icon={<EuroIcon />} statTitle={price} />}
            </StatsWrapper>

            {product.categories && (
              <Categories>
                {product.categories.nodes.map(category => (
                  <Category key={category.name}>
                    <Tag big>{category.name}</Tag>
                  </Category>
                ))}
              </Categories>
            )}
          </>
        )}
      </Content>
    </BackgroundWrapper>
  );
};

export default Banner;
