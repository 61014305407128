import React, { FC } from 'react';
import styled from 'styled-components';

import { mediaQuery } from 'src/theme/breakpoints';
import { colors } from 'src/theme/colors';

const StyledStatsIcon = styled.div`
  align-items: center;
  display: flex;
`;

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 10px;

  ${mediaQuery('md')} {
    font-size: 14px;
  }
`;

const Stats = styled.span`
  color: ${colors.white};
`;

interface StatsIconProps {
  icon: React.ReactNode;
  statTitle: string;
  statInfo?: string;
}

const StatsIcon: FC<StatsIconProps> = ({ icon, statTitle, statInfo }) => (
  <StyledStatsIcon>
    {icon}
    <StatsWrapper>
      <Stats>{statTitle}</Stats>
      {statInfo && <Stats>{statInfo}</Stats>}
    </StatsWrapper>
  </StyledStatsIcon>
);

export default StatsIcon;
