import React, { FC } from 'react';
import styled from 'styled-components';

import { mediaQuery } from 'src/theme/breakpoints';

const StyledMain = styled.main`
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1080px;
  padding: 40px 20px;

  ${mediaQuery('lg')} {
    padding: 90px 40px;
  } ;
`;

interface MainProps {
  children: React.ReactNode;
}

const Main: FC<MainProps> = ({ children }) => (
  <StyledMain>{children}</StyledMain>
);

export default Main;
